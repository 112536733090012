<template>
  <div class="controls">
    <div class="buttons">
      <div v-if="!running" class="controlButton startButton" @click="appControlClicked()">
        <i class="fas fa-play"></i>
      </div>
      <div v-else class="controlButton stopButton" @click="appControlClicked()">
        <i class="fas fa-pause"></i>
      </div>
      <div class='dataButtons'>
        <input type='file' @change='uploadFile' />
        <a :href="exportURL" target='_blank'><Button style='height: 100%;' label='Export settings' icon='fas fa-file-export' backgroundColor='#15171B' /></a>
      </div>
    </div>
    <div class="time">
      Current server time: {{ this.currentTime }}
    </div>
    <div class="addBot">
      <input type="text" placeholder="Steam ID" v-model="steamID">
      <Button class="addBotButton" label="Add bot" @click="addBot()" />
    </div>
  </div>
</template>

<script>
import { useToast, TYPE } from "vue-toastification";
import Button from "@/components/Button";

export default {
  name: "Controls",
  components: {
    Button,
  },
  setup() {

    const toast = useToast();
    return {toast}
  },
  data() {
    return {
      currentTime: new Date().toLocaleTimeString('en-us', {timeZone: "UTC"}),
      running: false,
      steamID: "",
      exportURL: ""
    }
  },
  async created() {
    setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString('en-us', {timeZone: "UTC"});
    }, 1000);
    await this.getStatus();
    this.exportURL = (process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/storage";
  },
  emits: ['reloadBots'],
  methods: {
    uploadFile(event) {
      let file = event.target.files[0];
      const fr = new FileReader();
      fr.onload = async (e) => {
        const data = JSON.parse(e.target.result);
        await this.importBots(data.bots)
      };
      fr.readAsText(file)
    },
    async importBots(bots) {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/storage/import", {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          bots: bots
        })
      });
      let response = await res.json();
      if(response.success) {
        this.$emit('reloadBots');
        this.toast.success(response.message);
      } else {
        this.toast.error(response.message);
      }
    },
    async appControlClicked() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/storage/" + (this.running ? "stop" : "start"), {
        credentials: 'include',
      })
      let response = await res.json();
      if(response.success) this.running = !this.running;
      this.toast(response.message, {type: response.success ? TYPE.SUCCESS : TYPE.ERROR});
    },
    async getStatus() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/storage/status", {
        credentials: 'include',
      })
      let response = await res.json();
      this.running = response.status[0];
    },
    async addBot() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/storage/add", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          steamID: this.steamID,
        })
      })
      let response = await res.json();
      if(response.success) this.steamID = "";
      this.toast(response.message, {type: response.success ? TYPE.SUCCESS : TYPE.ERROR});
      if(response.success) this.$emit('reloadBots');
    },
    async exportData() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/storage/export", {
        credentials: 'include',
      })
      let response = await res.json();
      if(response.success) this.toast(response.message, {type: response.success ? TYPE.SUCCESS : TYPE.ERROR});
    },
  }
}
</script>

<style scoped>

.controls {
  display: flex;
  position: relative;
  background: #1E242B;
  min-height: 4em;
  width: 100%;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
}

.buttons {
  left: 0;
  position: absolute;
  display: flex;
}

.controlButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #15171B;
  height: 3em;
  width: 3em;
  margin-left: 0.7em;
  cursor: pointer;
}

.startButton {
  color: rgb(138, 195, 41);
  border: 2px solid rgba(138, 195, 41, 0.5);
}

.stopButton {
  color: rgb(255, 50, 50);
  border: 2px solid rgba(255, 50, 50, 0.5);
}

.addBot {
  display: flex;
  position: absolute;
  right: 0;
  background: #15171B;
  border-radius: 12px;
  margin-right: 0.7em;
}

input {
  background: #15171B;
  border-radius: 12px;
  border: none;
  color: white;
  padding: 0 10px;
  font-size: 15px;
}

.addBotButton {
  height: 3em;
  width: 6em;
}

.dataButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 0.7em;
}

a {
  text-decoration: none;
}

</style>