<template>
  <div class="coinflip">
    <div class="leftSide">
      <div class="coinflipSide">
        <img v-if="coinflip.creator.side === 'ak'" class="sideImg" src='../../assets/red.svg' />
        <img v-else class="sideImg" src='../../assets/gray.svg' />
      </div>
      <img class="profileImage" :src="coinflip.creator.photo" />
      <div class="creatorName">{{coinflip.creator.name}}</div>
    </div>
    <div class='rightSide'>
      <div class="value">
        <div class="dollar">$</div>
        <div class="amount">{{coinflip.total_value}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Coinflip",
  props: {
    coinflip: Object,
  }
}
</script>

<style scoped>
.coinflip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 4em;
  background: #15171B;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 0.5em 0.7em;
}

.coinflipSide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileImage {
  width: 3em;
  height: 3em;
  border: solid 2px #2d3748;
  border-radius: 50%;

}

.leftSide {
  display: flex;
  align-items: center;
  gap: 0.7em;
}

.rightSide {
  display: flex;
  align-items: center;
  gap: 0.7em;
}

.dollar {
  color: rgba(200, 39, 33, 1);
}

.value {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: bold;
}
</style>