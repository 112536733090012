<template>
<div v-if="user && user.permissions && user.permissions.access_stats" class="affiliatePage">
  <div class="block">
    <div class="affiliateFilters">
      <input v-model='search' class="codeInput" type="text" placeholder="Enter a code..." @input='getAffiliates'/>
      <select v-model='perPage' class='perPage' @change='getAffiliates'>
        <option value='10'>10</option>
        <option value='25'>25</option>
        <option value='50'>50</option>
        <option value='100'>100</option>
      </select>
    </div>
    <div class="affiliateList">
      <div class="description affiliate">
        <div class="affiliateItem">Code</div>
        <div class="affiliateItem">Owner</div>
        <div class="affiliateItem">Depositors</div>
        <div class="affiliateItem">Uses</div>
        <div class="affiliateItem">Commission</div>
      </div>
      <div class="affiliate" v-for="affiliate in affiliates" :key='affiliates.indexOf(affiliate)' @click='loadAffiliateSnapshot(affiliate)'>
        <div class="affiliateItem">{{ affiliate.code }}</div>
        <div class="affiliateItem">{{ affiliate.owner}}</div>
        <div class="affiliateItem">{{ affiliate.total_depositors.toString()}}</div>
        <div class="affiliateItem">{{ affiliate.people_used.length }}</div>
        <div class="affiliateItem">${{ affiliate.total_earned.toFixed(2)}}</div>
      </div>
    </div>
    <div class="affiliatesPaging">
      <v-pagination
        v-model="page"
        :pages="pages"
        :range-size="1"
        active-color="#15171B"
        @update:modelValue="getAffiliates"
      />
    </div>
  </div>
  <div class="block">
    <div class="selectedAffiliate" v-if="selectedAffiliate">
      <h2>Affiliate - {{selectedAffiliate.code}}</h2>
      <div class="affiliateInfoBlocks">
        <div class="affiliateInfoBlock">
          <Row title='Code' :value='selectedAffiliate.code' />
          <Row title='Owner' :value='selectedAffiliate.owner' />
          <Row title='Tier' :value='getTier(selectedAffiliate.total_deposited).toString()' />
          <Row title='Depositors' :value='selectedAffiliate.total_depositors.toString()' />
          <Row title='Uses' :value='selectedAffiliate.people_used.length.toString()' />
        </div>
        <div class="affiliateInfoBlock">
          <Row title='Wagered' :dollar='true' :value='selectedAffiliate.total_wagered.toFixed(2)' />
          <Row title='Earned' :dollar='true' :value='selectedAffiliate.total_earned.toFixed(2)' />
          <Row title='Available' :dollar='true' :value='selectedAffiliate.available_claim.toFixed(2)' />
          <Row title='Deposited' :dollar='true' :value='selectedAffiliate.total_deposited.toFixed(2)' />
          <Row title='Withdrew' :dollar='true' :value='selectedAffiliate.total_withdrew.toFixed(2)' />
        </div>
      </div>
      <div v-if='selectedSnapshot' class="affiliateCharts">
        <SelectorMenu :buttons="['Used/Depositors', 'Deposited/Wagered']" v-model='selectedChart' />
        <AffiliateChart1 v-if='selectedChart === 0' :data="selectedSnapshot"/>
        <AffiliateChart2 v-if='selectedChart === 1' :data="selectedSnapshot"/>
      </div>
      <div class='topReferrals'>
        <h3 class='topReferralsTitle'>Referrals</h3>
        <div class='topReferralsList'>
          <div class="description referral">
            <div class="referralItem">Name</div>
            <div class="referralItem">SteamID</div>
            <div class="referralItem">Wagered</div>
            <div class="referralItem">Withdrawn</div>
            <div class="referralItem">Deposited</div>
            <div class="referralItem">Commission</div>
          </div>
          <div class='referral' v-for='referral in referrals' :key='referrals.indexOf(referral)'>
            <div class="referralItem">{{referral.name}}</div>
            <div class="referralItem">{{referral.steamID}}</div>
            <div class="referralItem">${{referral.affiliate.wagered.toFixed(2)}}</div>
            <div class="referralItem">${{referral.affiliate.withdraw.toFixed(2)}}</div>
            <div class="referralItem">${{referral.affiliate.deposited.toFixed(2)}}</div>
            <div class="referralItem">${{referral.affiliate.commission.toFixed(2)}}</div>
          </div>
        </div>
        <Button class='loadMoreButton' label='Load more...' @click='loadReferrals' />
      </div>
    </div>
    <h2 v-else>Select a code...</h2>
  </div>
</div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useToast } from 'vue-toastification'
import AffiliateChart1 from '@/components/Affiliates/AffiliateChart1'
import AffiliateChart2 from '@/components/Affiliates/AffiliateChart2'
import SelectorMenu from '@/components/Affiliates/SelectorMenu'
import Row from '@/components/Affiliates/Row'
import Button from '@/components/Button'

export default {
  name: 'Affiliates',
  components: {
    SelectorMenu,
    AffiliateChart1,
    AffiliateChart2,
    VPagination,
    Row,
    Button
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      search: "",
      page: 1,
      pages: 1,
      perPage: 10,
      affiliates: [],
      referrals: [],
      selectedAffiliate: undefined,
      selectedSnapshot: undefined,
      selectedChart: 0,
    }
  },
  setup() {
    const toast = useToast();
    return {toast}
  },
  async mounted() {
    await this.getAffiliates();

    if(this.$route.query.code) {
      this.search = this.$route.query.code;
      await this.getAffiliates();
      this.selectedAffiliate = this.affiliates.find(affiliate => affiliate.code === this.$route.query.code);
      if(this.selectedAffiliate) await this.loadAffiliateSnapshot(this.selectedAffiliate);
    }

  },
  methods: {
    async getAffiliates() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/affiliates?page=" + this.page + "&perPage=" + this.perPage + (this.search !== "" ? "&search=" + this.search : ""), {
        credentials: 'include',
      })
      try {
        let response = await res.json()
        if(response.success === true) {
          this.pages = Math.ceil(response.data.amount / this.perPage);
          this.affiliates = response.data.affiliates;
            } else {
          this.toast.error(response.message);
        }
      } catch (e) {
        console.log(e)
      }
    },
    async loadAffiliateSnapshot(affiliate) {
      await this.$router.push({ query: { code: affiliate.code } })

      this.selectedAffiliate = affiliate
      this.selectedSnapshot = undefined

      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/affiliates/snapshot?code=" + affiliate.code, {
        credentials: 'include',
      })
      try {
        let response = await res.json()
        if(response.success === true) {
          this.selectedSnapshot = response.data;
        } else {
          this.toast.error(response.message);
        }
      } catch (e) {
        console.log(e)
      }
    },
    async loadReferrals() {
      if(this.referrals.length / 10 !== 0) {
        this.toast.warning("All referrals loaded!")
        return
      }
      let page = (this.referrals.length / 10) + 1;
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/affiliates/referrals?code=" + this.selectedAffiliate.code + "&page=" + page, {
        credentials: 'include',
      })
      try {
        let response = await res.json()
        if(response.success === true) {
          this.referrals = this.referrals.concat(response.data)
        } else {
          this.toast.error(response.message);
        }
      } catch (e) {
        console.log(e)
      }
    },
    getTier(totalDeposited) {
      if (totalDeposited > 100000) {
        return 5
      } else if (totalDeposited> 50000) {
        return 4
      } else if (totalDeposited > 25000) {
        return 3
      } else if (totalDeposited > 10000) {
        return 2
      } else return 1
    }
  }
}
</script>

<style scoped>

.affiliatePage {
  position: relative;
  display: flex;
  width: 90%;
  height: 94%;
  padding: 3vh 5%;
  flex-direction: row;
  justify-content: space-between;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1E242B;
  width: 49%;
  border-radius: 15px;
}

.affiliateFilters {
  display: flex;
  height: 8%;
  width: 90%;
  padding: 0 5%;
  align-items: center;
  justify-content: center;
  gap: 2%;
}

.codeInput {
  height: 35%;
  width: 30%;
}

.perPage {
  height: 35%;
  width: 7%;
}

.affiliateList {
  height: 84%;
  width: 90%;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  gap: 1%;
  overflow-y: auto;
}

.affiliate {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 2.5em;
  cursor: pointer;
  background-color: #15171B;
  border-radius: 12px;
}

.affiliateItem {
  color: #999;
  width: 20%;
}

.description {
  font-weight: bold;
  cursor: default;
}

.affiliate:hover {
  background-color: rgba(21, 23, 27, 0.7);
}

.affiliatesPaging {
  height: 8%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.affiliateCharts {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.selectedAffiliate {
  width: 94%;
  height: 96%;
  padding: 2% 3%;
  overflow-y: auto;
}

.affiliateInfoBlocks {
  display: flex;
  justify-content: space-between;
}

.affiliateInfoBlock {
  background: #15171B;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 49%;
  padding: 1.5% 0;
}

.topReferrals {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.topReferralsList {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  padding-bottom: 1em;
}

.referralItem {
  width: 16.66%;
}

.loadMoreButton {
  width: 20%;
  height: 2em;
}

.referral {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 2.5em;
  background-color: #15171B;
  border-radius: 12px;
  width: 100%;
}

</style>