<template>
  <a :href="login" class="login-button">
    <img src="../assets/steamlogin.svg"  alt="Log-in button"/>
  </a>
</template>

<script>

require('dotenv').config()

export default {
  name: "LoginButton",
  computed:{
    login(){
      return (process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/auth/steam";
    }
  }
}
</script>

<style scoped>

.login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;
  width: 20em;
  background: linear-gradient(to right, #8ac329 0%, #4a7a16 60%);
}

img {
  width: 90%;
}
</style>

