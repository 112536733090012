<template>
<div v-if="bots" class="bots">
  <div class="chooseAndAdd">
    <BotsDropdown @changeBot="changeBot" :bots="bots" />
    <Button class="addBotButton" icon="fas fa-plus" @click="addingBot = !addingBot"/>
  </div>
  <div v-if="addingBot" class="botDetailsForm">
    <div class="inputs">
      <div class="botDetailsInput">
        <div>Username:</div>
        <input type="text" v-model="username">
      </div>
      <div class="botDetailsInput">
        <div>Password:</div>
        <input type="text" v-model="password">
      </div>
      <div class="botDetailsInput">
        <div>Shared secret:</div>
        <input type="text" v-model="sharedSecret">
      </div>
      <div class="botDetailsInput">
        <div>Identity secret:</div>
        <input type="text" v-model="identitySecret">
      </div>
      <Button class="addBotFinalButton" label="Add bot" @click="addBot"/>
    </div>
  </div>
  <div class="botInfo" v-else-if="botInfoLoaded">
    <div class="botInventoryCount">{{botInventoryCount}} Items</div>
    <div class="botInventoryAmount">
      <div class="dollar">$</div>
      <div>{{botInventoryValue}}</div>
    </div>
  </div>
  <div v-if="botInfoLoaded && botInventoryValue < 1000" class="runningOutWarning">
    Warning: Bot is running out of skins!
  </div>
</div>
</template>

<script>
import {useToast, TYPE} from "vue-toastification";

require('dotenv').config()
import BotsDropdown from "@/components/Influencers/BotsDropdown";
import Button from "@/components/Button";
export default {
  name: "Bots",
  components: {BotsDropdown, Button},
  emits: ['changeBot'],
  setup() {
    const toast = useToast();
    return {toast}
  },
  data() {
    return {
      bots: [],
      addingBot: false,
      username: "",
      password: "",
      sharedSecret: "",
      identitySecret: "",
      botInfoLoaded: false,
      botInventoryCount: {
        type: Number,
        default: 0,
      },
      botInventoryValue: {
        type: String,
        default: "0.00",
      }
    }
  },
  async mounted() {
    await this.getBots();
  },
  methods: {
    async changeBot(bot) {
      this.$emit('changeBot', bot);
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/bots/info?bot=" + bot, {
        credentials: 'include',
      })
      try {
        let response = await res.json()
        if (response.success) {
          this.botInventoryCount = response.itemsCount;
          this.botInventoryValue = response.itemsValue;
          this.botInfoLoaded = true;
        } else {
          this.toast.error(response.message);
        }
      } catch (e) {
        console.log(e)
      }
    },
    async addBot() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/bots/add", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: this.username,
          password: this.password,
          sharedSecret: this.sharedSecret,
          identitySecret: this.identitySecret,
        })
      })
      this.addingBot = false;
      let response = await res.json();
      await this.getBots();
      this.toast(response.message, {type: response.success ? TYPE.SUCCESS : TYPE.ERROR});
    },
    async getBots() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/bots", {
        credentials: 'include',
      })
      try {
        let response = await res.json()
        if(response.success === true) {
          this.bots = response.bots;
        } else {
          this.toast.error(response.message);
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>
.bots {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chooseAndAdd {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown {
  width: 87%;
  height: 100%;
  margin-right: 3%;
}

.addBotButton {
  height: 100%;
  width: 12%;
}

.botDetailsForm {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  width: 100%;
  height: 70%;
}

.botDetailsInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

input {
  color: white;
  background-color: #1E242B;
  border: solid 2px #2d333c;
  border-radius: 7px;
  display: flex;
  text-align: center;
  align-items: center;
}

.addBotFinalButton {
  height: 20%;
  width: 100%;
}

.botInfo {
  display: flex;
  margin: 4% 0;
  padding: 3%;
  border-radius: 12px;
  justify-content: space-between;
  background-color: #15171b;
}

.botInventoryAmount {
  display: flex;
}

.dollar {
  color: #c82721;
  font-weight: 500;
}

.runningOutWarning {
  padding: 2%;
  border-radius: 12px;
  background-color: rgba(255,184,24, 0.7);
}
</style>
