<template>
  <div v-if="user" class="header">
    <div class="menu">
      <router-link class="menuItem" to="/">Home</router-link>
      <router-link v-if='user.permissions && user.permissions.view_history' class="menuItem" to="/history">History</router-link>
      <router-link v-if='user.permissions && user.permissions.access_stats' class="menuItem" to="/stats">Stats</router-link>
      <router-link v-if='user.permissions && user.permissions.access_pushing' class="menuItem" to="/storage">Storage</router-link>
      <router-link v-if='user.permissions && user.permissions.access_finance' class="menuItem" to="/finance">Finance</router-link>
      <router-link v-if='user.permissions && user.permissions.access_affiliates' class="menuItem" to="/affiliates">Affiliates</router-link>
      <router-link v-if='user.permissions && (user.permissions.access_users || user.permissions.access_users_limited)' class="menuItem" to='/users'>Users</router-link>
      <router-link v-if="user.permissions && user.permissions.access_prices" class="menuItem" to="/prices">Prices</router-link>
    </div>
    <div class="userDetails">
      <img class="avatar" :src="user.avatarfull" alt="avatar"/>
      <div class="username">{{user.personaname}}</div>
    </div>
    <a class="wrapper" :href="logout">
      <Button class="log-out" label="Logout" background-color="rgba(200, 39, 33, 1)" />
    </a>
  </div>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "Header",
  components: {
    Button
  },
  props: {
    user: {
      type: Object,
      default: null,
    }
  },
  computed: {
    logout() {
      return (process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/logout";
    }
  }
}
</script>

<style scoped>
.header {
  z-index: 10;
  display: flex;
  position: fixed;
  justify-content: space-between;
  padding: 0 1.5%;
  box-sizing: border-box;
  align-items: center;
  background: #1E242B;
  width: 100%;
  height: 5%;
  top: 0;
}

.wrapper {
  height: 60%;
  width: 7%;
  text-decoration: none;
  min-width: 5em;
}

.log-out {
  height: 100%;
  width: 100%;
}

.userDetails {
  display: flex;
  height: 100%;
  align-items: center;
}

.username {
  margin-left: 10px;
}

.avatar {
  height: 60%;
  border-radius: 50%;
  border: solid 2px #505050;
}

.menuItem {
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  width: auto;
}

.menuItem:hover {
  background-color: rgba(0,0,0,0.1);
}

.menu {
  height: 60%;
  display: flex;
  align-items: center;
}

</style>
