<template>
  <div v-if="user && user.permissions" class="mainContent">
    <div class="controlSection">
      <div class="subSection">
        <div class="subSectionTitle">Manage Bots</div>
        <div v-if="user.permissions.manage_bots" class="subSectionContent">
          <Bots @changeBot="changeBot" />
        </div>
        <div v-else class="subSectionContent noPermission">You do not have permissions!</div>
      </div>
      <div class="subSection">
        <div class="subSectionTitle">Manage Access</div>
        <div v-if="user.permissions.manage_access" class="subSectionContent">
          <UsersDropdown />
        </div>
        <div v-else class="subSectionContent noPermission">You do not have permissions!</div>
      </div>
      <div class="subSection">
        <div class="subSectionTitle">Custom offer</div>
        <div v-if="user.permissions.send_custom" class="subSectionContent">
          <CustomOffer @sendOffer="sendOffer" />
        </div>
        <div v-else class="subSectionContent noPermission">You do not have permissions!</div>
      </div>
    </div>
    <div class="partnersSection">
      <div class="partnersSectionTitle">
        <div class="partnersSectionTitleText">Partners</div>
        <Button v-if="user.permissions.manage_partners" class="partnerAddButton" label="Add Partner" @click="addPartnerButton"/>
      </div>
      <div v-if="user.permissions.send_partner && partnersLoaded" class="partnersSectionContent">
        <NewPartner v-if="addPartner" @addPartner="addPartnerFromData" />
        <div class="partnerHeader">
          <div class="username">Name</div>
          <div class="steamID">Steam ID</div>
          <div class="amount">Amount</div>
          <div class="wager">Wager</div>
          <div class="buttons">Actions</div>
        </div>
        <Partner v-for="partner in partners" :key="partner.index" :partner="partner" @sendOffer="sendOffer" @removePartner="removePartner"/>
      </div>
    </div>
  </div>
</template>

<script>
import UsersDropdown from "@/components/Influencers/UsersDropdown";
require('dotenv').config()

import Bots from "@/components/Influencers/Bots";
import Partner from "@/components/Influencers/Partner";
import NewPartner from "@/components/Influencers/NewPartner";
import CustomOffer from "@/components/Influencers/CustomOffer";
import {TYPE, useToast} from "vue-toastification";
import Button from "@/components/Button";

export default {
  name: 'Home',
  components: {
    CustomOffer,
    UsersDropdown,
    NewPartner,
    Partner,
    Bots,
    Button
  },
  setup() {
    const toast = useToast();
    return {toast}
  },
  data() {
    return {
      partners: Array,
      partnersLoaded: Boolean,
      addPartner: false,
      selectedBot: null,
    }
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  async mounted() {
    await this.getPartners();
  },
  methods: {
    changeBot(bot) {
      this.selectedBot = bot;
    },
    async sendOffer(data) {
      let partner = data.partner;

      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/partners/send", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          partnerId: partner._id,
        })
      })

      let response = await res.json();
      this.toast(response.message, {type: response.success ? TYPE.SUCCESS : TYPE.ERROR});
    },
    addPartnerButton() {
      this.addPartner = !this.addPartner;
    },
    async addPartnerFromData(data) {
      this.addPartner = false;
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/partners/add", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      let response = await res.json();
      await this.getPartners();
      this.toast(response.message, {type: response.success ? TYPE.SUCCESS : TYPE.ERROR});
    },
    async removePartner(id) {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/partners/remove", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(id)
      })
      let response = await res.json();
      await this.getPartners();
      this.toast(response.message, {type: response.success ? TYPE.SUCCESS : TYPE.ERROR});
    },
    async getPartners() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/partners", {
        credentials: 'include',
      })
      try {
        let response = await res.json()
        if(response.success === true) {
          this.partnersLoaded = true;
          this.partners = response.partners;
        } else {
          this.toast.error(response.message);
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>
.mainContent {
  width: 90%;
  overflow-y: auto;
  height: 94%;
  padding: 3vh 5%;
}

.controlSection {
  display: flex;
  justify-content: space-between;
  height: 35%;
}

.subSection {
  display: flex;
  height: 100%;
  width: 31%;
  flex-direction: column;
}

.subSectionTitle {
  height: 12%;
  display: flex;
  font-size: 18px;
  margin-left: 7px;
  align-items: center;
}

.subSectionContent {
  display: flex;
  flex-direction: column;
  padding: 4%;
  height: 70%;
  border-radius: 15px;
  background: #1E242B;
}

.noPermission {
  color: #c82721;
  justify-content: center;
  align-items: center;
}

.partnersSection {
  margin-top: 1%;
  height: 60%;
}

.partnersSectionTitle {
  height: 8%;
  display: flex;
  margin: 0 0 0 7px;
  align-items: center;
  justify-content: space-between;
}

.partnersSectionTitleText {
  font-size: 18px;
}

.partnerAddButton {
  height: 70%;
  width: 12%;
}

.partnersSectionContent {
  height: 92%;
  width: 100%;
  overflow-y: auto;
}

.partnerHeader {
  display: flex;
  height: 4em;
  border-radius: 15px;
  margin-bottom: 0.7em;
  background: #1E242B;
  align-items: center;
  padding: 0 15px 0 15px;
  font-weight: bold;
}

.partnerHeader .username {
  display: flex;
  border-right: solid 2px #15171B;
  height: 100%;
  width: 20%;
  align-items: center;
  justify-content: center;
}

.partnerHeader .steamID {
  overflow: hidden;
  height: 100%;
  width: 40%;
  border-right: solid 2px #15171B;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partnerHeader .amount,
.partnerHeader .wager {
  display: flex;
  height: 100%;
  width: 10%;
  border-right: solid 2px #15171B;
  align-items: center;
  justify-content: center;
}

.partnerHeader .buttons {
  display: flex;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
}
</style>
