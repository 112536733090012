<template>
<div class="customOffer">
  <div class="inputs">
    <div class="topPart">
      <div class="username">
        <label class="mobile" for="username">Username:</label>
        <input id="username" type="text" v-model="username">
      </div>
      <div class="amount">
        <label class="mobile" for="amount">Amount:</label>
        <input id="amount" type="number" v-model="amount">
      </div>
    </div>
    <div class="tradeurl">
      <label class="mobile" for="tradeurl">Trade URL:</label>
      <input id="tradeurl" type="text" v-model="tradeurl">
    </div>
  </div>
  <Button class="sendCustomButton" label="Send Offer" @click="sendOffer"/>
</div>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "CustomOffer",
  components: {Button},
  emits: ['sendOffer'],
  data() {
    return {
      username: "",
      tradeurl: "",
      amount: 0,
    }
  },
  methods: {
    sendOffer() {
      this.$emit('sendOffer', {partner: {
        name: this.username,
        tradeurl: this.tradeurl,
        amount: this.amount,
        }});
    }
  }
}
</script>

<style scoped>
input {
  height: 100%;
  color: white;
  background-color: #1E242B;
  border: solid 2px #2d333c;
  border-radius: 7px;
  display: flex;
  text-align: center;
  align-items: center;
}

.customOffer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

label {
  display: flex;
}

.sendCustomButton {
  height: 15%;
  width: 100%;
}

.inputs {
  height: 85%;
}

.topPart {
  display: flex;
  height: 27%;
  justify-content: space-between;
  margin-bottom: 3%;
}

.tradeurl {
  display: flex;
  width: 100%;
  height: 27%;
  flex-direction: column;
}

.username {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.amount {
  display: flex;
  flex-direction: column;
  width: 45%;
}

</style>
