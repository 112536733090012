<template>
  <div v-if='vault' class='userVault'>
    <div class='balance'>Vaulted: <input id='balance' type='number' placeholder='Enter Balance...' v-model='balance' @change='updateVault' /></div>
    <div class='stats'>
      <div>Total Added: <span class='amount added'>${{ vault.stats.total_added.toFixed(2) }}</span></div>
      <div>Total Removed: <span class='amount removed'>${{ vault.stats.total_removed.toFixed(2) }}</span></div>
    </div>
    <div v-if='vault.lock.locked' class='lock'>
      <div>Locked until: {{ new Date(vault.lock.lock_end_date).toLocaleString() }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserVault',
  data() {
    return {
      balance: { type: Number, default: 0 },
    }
  },

  mounted() {
    if (this.vault) {
      this.balance = this.vault.balance || 0
    }
  },

  watch: {
    vault: {
      handler: function (val) {
        this.balance = val.balance
      },
      deep: true
    }
  },
  props: {
    vault: {
      type: Object,
      required: true
    },
  },
  methods: {
    updateVault() {
      this.$emit('updateVault', this.balance)
    }
  }
}
</script>

<style scoped>
.userVault {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.balance {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}

.stats {
  display: flex;
  justify-content: space-around;
  border: 2px solid #2D333C;
  border-radius: 12px;
  padding: 0.5em 0;
  width: 100%;
}

.amount {
  font-size: 14px;
  font-weight: bold;
}

.added {
  color: #41FF68;
}

.removed {
  color: #F83C63;
}

.lock {
  font-size: 14px;
  color: #FFA500;
  width: 100%;
  text-align: center;
}
</style>