<template>
  <div v-if='user.affiliate' class='affiliateInfo'>
    <div v-if='user.affiliate.code_used'>Claimed code: <span class='code_used'>{{ user.affiliate.code_used }}</span></div>
    <div v-if='user.affiliate.case_opened' class='case'>
      <div class='caseItemInfo'>
        <div class='caseItemOpened'>Opened from case:</div>
        <div v-if='user.affiliate.case_opened.item' class='caseItemName'>{{ user.affiliate.case_opened.item.name }}</div>
      </div>
      <img v-if='user.affiliate.case_opened?.item?.icon' class='caseItemImage' :src="'http://cdn.steamcommunity.com/economy/image/' + user.affiliate.case_opened.item.icon"  alt='case item'/>
    </div>
    <div class='stats'>
      <div class='stat'>Commission<span class='value'>${{ user.affiliate.commission.toFixed(2)}}</span></div>
      <div class='stat'>Wagered<span class='value'>${{ user.affiliate.wagered.toFixed(2)}}</span></div>
      <div class='stat'>Withdrawn<span class='value'>${{ user.affiliate.withdraw.toFixed(2)}}</span></div>
      <div class='stat'>Deposited<span class='value'>${{ user.affiliate.deposited.toFixed(2)}}</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AffiliateInfo',
  props: {
    user: {
      type: Object,
    },
  },
}
</script>

<style scoped>
.affiliateInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.caseItemImage {
  height: 4em;
}

.case {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2em;
  padding: 0 0.7em;
  justify-content: center;
}

.caseItemInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2em;
  padding: 0 0.7em;
}

.code_used {
  font-weight: bold;
  font-size: 20px;
}

a {
  text-decoration: none;
  color: white;
}

.stats {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.value {
  font-weight: bold;
  color: rgba(51, 142, 255, 1)
}

.stat {
  display: flex;
  flex-direction: column;
}
</style>