<template>
  <div class='row'>
    <div class='title'>{{ title }}:</div>
    <div class='value'>{{ (dollar ? '$' : '') }}{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'Row',
  props: {
    title: String,
    value: String,
    dollar: Boolean
  },
}
</script>

<style scoped>
.row {
  display: flex;
  height: 2em;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.title {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-right: 2px solid #333;
}

.value {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>