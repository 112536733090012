<template>
<div class="partner" v-if="partner">
  <div class="username">{{partner.name}}</div>
  <div class="steamID">
    {{ partner.steamID }}
  </div>
  <div class="amount">
    <div class="dollar">$</div>
    <div class="value">{{partner.value}}</div>
  </div>
  <div class="wager">
    <div class="dollar">$</div>
    <div class="value">{{partner.wager}}</div>
  </div>
  <div class="buttons">
    <Button class="sendButton" label="Send" text-color="#fff" @click="sendOffer"/>
    <Button class="removeButton" icon="fas fa-times" background-color="rgba(200, 39, 33, 1)" @click="removePartner"/>
  </div>
</div>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "Partner",
  components: {
    Button
  },
  props: {
    partner: Object,
  },
  emits: ['removePartner', 'sendOffer'],
  methods: {
    sendOffer() {
      this.$emit('sendOffer', {partner: this.partner});
    },
    removePartner() {
      this.$emit('removePartner', {id: this.partner._id});
    }
  }
}
</script>

<style scoped>
.partner {
  display: flex;
  height: 4em;
  border-radius: 15px;
  margin-bottom: 0.7em;
  background: #1E242B;
  align-items: center;
  padding: 0 15px 0 15px;
}

.username {
  display: flex;
  border-right: solid 2px #15171B;
  height: 100%;
  width: 20%;
  align-items: center;
  justify-content: center;
}


.steamID {
  overflow: hidden;
  height: 100%;
  width: 40%;
  border-right: solid 2px #15171B;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amount, .wager {
  display: flex;
  height: 100%;
  width: 10%;
  border-right: solid 2px #15171B;
  align-items: center;
  justify-content: center;
}

.dollar {
  color: #c82721;
  font-weight: 500;
}

.buttons {
  display: flex;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
}

.sendButton {
  height: 50%;
  width: 40%;
}

.removeButton {
  height: 50%;
  margin-left: 3%;
  width: 15%;
}
</style>
