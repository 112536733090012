<template>
  <div class="content" v-if="user && user.permissions && user.permissions.view_history">
    <div v-if="loaded && history.length > 0" class="stats">
      <div class="statsItem">
        <div>Daily expenses:</div>
        <div class="statsAmount">
          <div class="dollar statsDollar">$</div>
          <div class="statsValue">{{day.toFixed(2)}}</div>
        </div>
        <div class="buttons">
          <div class="statsButton" @click="dayHandler(-1); getStats()"><i class="fas fa-chevron-left"></i></div>
          <div class="date">{{dayDate.toISOString().split('T')[0]}}</div>
          <div class="statsButton" @click="dayHandler(1); getStats()"><i class="fas fa-chevron-right"></i></div>
        </div>
      </div>
      <div class="statsItem">
        <div>Weekly expenses:</div>
        <div class="statsAmount">
          <div class="dollar statsDollar">$</div>
          <div class="statsValue">{{week.toFixed(2)}}</div>
        </div>
        <div class="buttons">
          <div class="statsButton" @click="weekHandler(-7); getStats()"><i class="fas fa-chevron-left"></i></div>
          <div class="date">{{weekDate.toISOString().split('T')[0]}}</div>
          <div class="statsButton" @click="weekHandler(+7); getStats()"><i class="fas fa-chevron-right"></i></div>
        </div>
      </div>
      <div class="statsItem">
        <div>Monthly expenses:</div>
        <div class="statsAmount">
          <div class="dollar statsDollar">$</div>
          <div class="statsValue">{{month.toFixed(2)}}</div>
        </div>
        <div class="buttons">
          <div class="statsButton" @click="monthHandler(-1); getStats()"><i class="fas fa-chevron-left"></i></div>
          <div class="date">{{monthDate.toISOString().split('T')[0]}}</div>
          <div class="statsButton" @click="monthHandler(1); getStats()"><i class="fas fa-chevron-right"></i></div>
        </div>
      </div>
    </div>
    <div v-if="loaded && history.length > 0" class="items">
      <div class="historyItem historyHeader">
        <div class="subItem">Name</div>
        <div class="subItem">Steam ID</div>
        <div class="subItem">Date</div>
        <div class="amount">Value</div>
        <div class="wager">Wager</div>
      </div>
      <div class="historyItem" v-for="(item, index) in history.slice(0, 100)" :key="index">
        <div class="subItem">{{item.name}}</div>
        <div class="subItem">{{item.steamID}}</div>
        <div class="subItem">{{new Date(item.createdAt).toLocaleString()}}</div>
        <div class="amount">
          <div class="dollar">$</div>
          <div class="value">{{item.value.toFixed(2)}}</div>
        </div>
        <div class="wager">
          <div class="dollar">$</div>
          <div class="value">{{item.wager.toFixed(2)}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="noPermission" v-else>You do not have permissions!</div>
</template>

<script>
import {useToast} from "vue-toastification";

export default {
  name: "History",
  props: {
    user: {
      type: Object,
      default: null,
    }
  },
  setup() {
    const toast = useToast();
    return {toast}
  },
  data() {
    return {
      loaded: {
        type: Boolean,
        default: false,
      },
      history: {
        type: Array,
        default: [],
      },
      dayDate: new Date(),
      day: 0,
      monthDate: new Date(new Date().setDate(1)),
      month: 0,
      weekDate: new Date(new Date().setDate(new Date().getDate() - new Date().getDay())),
      week: 0,
    }
  },
  async mounted() {
    await this.getHistory();
  },
  methods: {
    dayHandler(i) {
      let d = new Date()
      d.setFullYear(this.dayDate.getFullYear(), this.dayDate.getMonth(), this.dayDate.getDate() + i)
      this.dayDate = d;
    },
    weekHandler(i) {
      let d = new Date()
      d.setFullYear(this.weekDate.getFullYear(), this.weekDate.getMonth(), this.weekDate.getDate() + i);
      this.weekDate = d;
    },
    monthHandler(i) {
      let d = new Date()
      d.setFullYear(this.monthDate.getFullYear(), this.monthDate.getMonth() + i, 1)
      this.monthDate = d;
    },
    getStats() {
      this.month = 0;
      this.day = 0;
      this.week = 0;

      this.history.forEach(item => {
        let createdAt = new Date(item.createdAt);
        if(createdAt.getMonth() === this.monthDate.getMonth() && createdAt.getFullYear() === this.monthDate.getFullYear()) {
          this.month += item.value;
        }
        if(createdAt.getDate() === this.dayDate.getDate() && createdAt.getMonth() === this.dayDate.getMonth() && createdAt.getFullYear() === this.dayDate.getFullYear()) {
          this.day += item.value;
        }
        if(Math.round(createdAt.getTime() - this.weekDate.getTime()) / (1000 * 60 * 60 * 24) < 7 && (createdAt > this.weekDate) && createdAt.getFullYear() === this.weekDate.getFullYear()) {
          this.week += item.value;
        }
      })
    },
    async getHistory() {
      const res = await fetch((process.env.VUE_APP_MODE === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_DEV) + "/history", {
        credentials: 'include',
      })
      try {
        let response = await res.json()
        if(response.success) {
          this.history = response.history.reverse();
          this.loaded = true;
          this.getStats();
        } else {
          this.toast.error(response.message);
        }
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>

<style scoped>
.content {
  height: 94%;
  padding: 3vh 5%;
}

.noPermission {
  position: relative;
  top: 50%;
  color: #c82721;
  justify-content: center;
  align-items: center;
}

.stats {
  display: flex;
  height: 18%;
  margin-right: 0.5%;
  margin-bottom: 2%;
  justify-content: space-between;
}

.statsItem {
  width: 32%;
  font-size: 17px;
  background-color: #1E242B;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.statsAmount {
  display: flex;
  padding-top: 1%;
  font-size: 18px;
  align-items: baseline;
}

.statsDollar {
  font-size: 15px;
}

.buttons {
  display: flex;
  position: relative;
  width: 100%;
  height: 2em;
  top: 6%;
  align-items: center;
  justify-content: center;
}

.date {
  margin: 0 2%;
}

.statsButton {
  display: flex;
  width: 5%;
  height: 100%;
  font-size: 20px;
  background-color: #15171B;
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  margin: 0 0.3%;
}

.statsButton:active {
  background-color: rgba(21, 23, 27, 0.75);
}

.items {
  overflow: scroll;
  height: 80%;
}

.historyItem {
  display: flex;
  height: 8%;
  border-radius: 15px;
  margin-bottom: 0.5%;
  background: #1E242B;
  align-items: center;
  padding: 0 15px 0 15px;
}

.subItem {
  display: flex;
  overflow: hidden;
  border-right: solid 2px #15171B;
  height: 100%;
  width: 25%;
  align-items: center;
  justify-content: center;
}

.amount, .wager {
  display: flex;
  height: 100%;
  width: 12.5%;
  align-items: center;
  justify-content: center;
}

.amount {
  border-right: solid 2px #15171B;
}

.dollar {
  color: #c82721;
  font-weight: 500;
}
</style>
