<template>
  <div class="selectorMenu">
    <div v-for="button in buttons" :key="buttons.indexOf(button)" :class="'selectorButton' + ' ' + (modelValue === buttons.indexOf(button) ? 'selectedSelector' : '')" @click="$emit('update:modelValue', buttons.indexOf(button))">
      {{ button }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectorMenu',
  props: ['modelValue', 'buttons'],
  emits: ['update:modelValue']
}
</script>

<style scoped>
.selectorMenu {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2% 0 1% 0;
  gap: 0.5em;
  height: 2.5em;
}

.selectorButton {
  box-sizing: border-box;
  height: 100%;
  width: 10em;
  border-radius: 10px;
  background-color: #15171B;
  color: white;
  padding: 0.8em;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedSelector {
  border-width: 2px;
  border-style: solid;
  border-color: rgba(45, 141, 255, 0.8);
}

</style>