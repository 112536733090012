<template>
  <div v-if="selectedOffer && offersType === `depositwd`" class="offer">
    <div class="info">
      <div class="type">{{ selectedOffer.type }}</div>
      <div class="date">{{
        new Date(selectedOffer.date).toLocaleString()
      }}</div>
      <div class="gameType" v-if="selectedOffer.game_type">{{
        selectedOffer.game_type
      }}</div>
      <div class="gameType" v-else>-</div>
      <div v-if="selectedOffer.price" class="price"
        >${{ selectedOffer.price.toFixed(2) }}</div
      >
    </div>
    <div
      v-if="selectedOffer.itemsList && selectedOffer.itemsList.length > 0"
      class="items">
      {{
        selectedOffer.itemsList
          .map((el) => el.name + `${el.amount ? ' x' + el.amount : ''}`)
          .join(', ')
      }}
    </div>

    <vue-json-pretty
      :data="selectedOffer"
      :deep="1"
      :showLength="true"
      :showLine="false"
      :showIcon="true"
      :virtual="true"
      :showDoubleQuotes="false" />
  </div>

  <div v-if="selectedOffer && offersType === `transactions`" class="offer">
    <div class="info">
      <div class="type">{{ selectedOffer.type }}</div>
      <div class="date">{{
        new Date(selectedOffer.date).toLocaleString()
      }}</div>
      <div class="gameType" v-if="selectedOffer.game_type">{{
        selectedOffer.game_type
      }}</div>
      <div class="gameType" v-else>-</div>
      <div v-if="selectedOffer.price" class="price"
        >${{ selectedOffer.price.toFixed(2) }}</div
      >
    </div>
    <div
      v-if="selectedOffer.itemsList && selectedOffer.itemsList.length > 0"
      class="items">
      {{
        selectedOffer.itemsList
          .map((el) => el.name + `${el.amount ? ' x' + el.amount : ''}`)
          .join(', ')
      }}
    </div>

    <vue-json-pretty
      :data="selectedOffer"
      :deep="1"
      :showLength="true"
      :showLine="false"
      :showIcon="true"
      :virtual="true"
      :showDoubleQuotes="false" />
  </div>

  <div v-if="selectedOffer && offersType === `balance`" class="offer">
    <div class="info">
      <div class="type">Balance Update</div>
      <div class="date">{{
        new Date(selectedOffer.date).toLocaleString()
      }}</div>
      <div class="balance">${{ selectedOffer.balance.toFixed(2) }}</div>
      <div class="change"
        >{{ selectedOffer.change > 0 ? '+' : '-' }}${{
          Math.abs(selectedOffer.change).toFixed(2)
        }}</div
      >
    </div>
  </div>

  <div v-if="selectedOffer && offersType === `games`" class="offer">
    <vue-json-pretty
      :data="selectedOffer"
      :deep="1"
      :showLength="true"
      :showLine="false"
      :showIcon="true"
      :virtual="true"
      :showDoubleQuotes="false" />
  </div>
</template>

<script>
  import { useToast } from 'vue-toastification'
  import VueJsonPretty from 'vue-json-pretty'
  import './jsonstyle.css'

  export default {
    name: 'Offer',
    emits: ['updateSelectedOffer'],

    components: {
      VueJsonPretty
    },

    /* -------- SETUP -------- */
    setup() {
      const toast = useToast()
      return { toast }
    },

    /* -------- DATA -------- */
    data() {
      return {
        statuses: [
          'bot_pending',
          'bot_processing',
          'bot_sent',
          'bot_sent_checking',
          'bot_sent_received',
          'bot_invalid_items',
          'bot_error',
          'user_created',
          'user_pending',
          'user_processing',
          'user_active',
          'user_accepted',
          'user_invalid_items',
          'user_cannot_send',
          'user_invalid_url',
          'user_no_authenticator',
          'user_expired',
          'user_countered',
          'user_canceled',
          'user_declined',
          'user_canceled_by_second_factor'
        ],
        status: ''
      }
    },

    /* -------- PROPS -------- */
    props: {
      offersType: {
        type: String,
        default: undefined
      },
      selectedOffer: {
        type: Object,
        default: undefined
      }
    },

    /* -------- WATCH -------- */
    watch: {
      selectedOffer: {
        async handler(newOffer) {
          this.status = newOffer.status
        },
        deep: true
      }
    },

    /* -------- MOUNTED -------- */
    mounted() {
      if (this.selectedOffer) {
        this.status = this.selectedOffer.status
      }
    },

    /* -------- METHODS -------- */
    methods: {
      updateStatus: async function () {
        const res = await fetch(
          (process.env.VUE_APP_MODE === 'production'
            ? process.env.VUE_APP_API_URL
            : process.env.VUE_APP_API_URL_DEV) + '/user/updateTransaction',
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id: this.selectedOffer._id,
              row: 'status',
              value: this.status
            })
          }
        )
        let response = await res.json()
        if (!response.success) {
          this.toast.error(response.message)
        } else {
          if (response.data.updated) {
            this.$emit('updateSelectedOffer', response.data.offer)
            this.toast.success('Success: Status updated!')
          }
        }
      }
    }
  }
</script>

<style scoped>
  .offer {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
    width: 100%;
    height: 100%;
  }

  .info {
    display: grid;
    width: 100%;
    grid-template-columns: 25% 30% 30% 15%;
    background-color: rgba(21, 23, 27, 1);
    border-radius: 12px;
    padding: 0.5em 0;
    align-items: center;
    justify-content: center;
  }

  .items {
    display: flex;
    background-color: rgba(21, 23, 27, 1);
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    gap: 0.3em;
    padding: 0.5em;
  }
</style>
