<template>
  <div v-if="bots" class="dropdown">
    <select v-model="selectedBot" @change="selectChange">
      <option v-for="(bot, index) in bots" :key="index" class="botButton">{{bot.username}}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "BotsDropdown",
  emits: ['changeBot'],
  props: {
    bots: Array,
  },
  data() {
    return {
      selectedBot: Object,
    }
  },
  methods: {
    selectChange() {
      this.$emit('changeBot', this.selectedBot);
    }
  }
}
</script>

<style scoped>
.dropdown {
  width: 70%;
  height: 100%;
  margin-right: 7%;
}

select {
  color: white;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  background: #1e242b;
  border: solid 2px #2d333c;
}
</style>
